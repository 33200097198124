function inputBox(placeholder) {
    return (
        <input
            type="text"
            name={"score-"}
            className="w-full py-2 pl-3 pr-10 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}
        />
    )
}

export function ShowScoreInput() {
    let scoreFormItems = [
        { label: 'Game 1' },
        { label: 'Game 2' },
        { label: 'Game 3' },
        { label: 'Game 4' },
        { label: 'Game 5' },
        { label: 'Game 6' },
        { label: 'Game 7' },
        { label: 'Game 8' },
        { label: 'Game 9' },
    ]

    console.log("scoreFormItems")
    console.log(scoreFormItems)
    return (
        <>
            <div className="mt-6 grid grid-cols-3 gap-0 max-w-xs">
                <div className="font-bold col-span-3">Scores</div>
                {scoreFormItems.map((item, index) => (
                    <>
                        <div className="col-span-3">&nbsp;</div>
                        <div className="row-span-3">
                            {item.label}
                        </div>
                        <div>{inputBox("Home")}</div>
                        <div>{inputBox("Away")}</div>
                        <div>{inputBox("Home")}</div>
                        <div>{inputBox("Away")}</div>
                        <div>{inputBox("Home")}</div>
                        <div>{inputBox("Away")}</div>
                    </>
                ))}
            </div>
        </>
    )
}