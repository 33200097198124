import React, { useEffect, useState } from "react";
import { useAxiosGet } from "../Hooks/HttpRequests";
import awsExports from '../aws-exports';
import Dropdown from "../Components/UIElements/Dropdown";
import ModalLoader from "../Components/UIElements/ModalLoader";
import { ShowScoreInput } from "../Components/Elements/ScorecardScoreInput";
import { ShowPlayersForm } from "../Components/Elements/ScorecardPlayerInput";


function Scorecard() {
    let [showLoadingModal, setShowLoadingModal] = useState(false)
    let [leagueRequestParams, setLeagueRequestParams] = useState({}) // eslint-disable-line
    let [venueRequestParams, setVenueRequestParams] = useState({}) // eslint-disable-line
    let [teamsRequestParams, setTeamsRequestParams] = useState(null)
    let [showPlayers, setShowPlayers] = useState(true)

    // let venueRequestParams = {}
    const api_url = awsExports.API_GATEWAY_URL

    let leagues = useAxiosGet(api_url + '/leagues', leagueRequestParams)
    let venues = useAxiosGet(api_url + '/venues', venueRequestParams)

    let [selectedLeague, setSelectedLeague] = useState('')
    let teams = useAxiosGet(api_url + '/teams', teamsRequestParams)

    useEffect(() => {
        setShowLoadingModal(leagues.loading)
    }, [leagues])

    useEffect(() => {
        console.log("selectedLeague : ")
        console.log(selectedLeague)
        if (selectedLeague.trim() !== '') {
            let selectedLeagueSplit = selectedLeague.split('Division')
            setTeamsRequestParams({
                "league": selectedLeagueSplit[0].trim(),
                "division": selectedLeagueSplit[1].trim(),
            })
            // setShowPlayers(true)
        }
    }, [selectedLeague])

    useEffect(() => {
        setShowLoadingModal(teams.loading)
    }, [teams])

    console.log("Rendering html")

    return (
        <div>
            {showLoadingModal ? (
                <ModalLoader></ModalLoader>
            ) : ''}
            <p className="text-center mt-2 mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Match Scorecard
            </p>

            <div className="md:flex mt-1 max-w-2xl">
                <div className="md:w-1/3">
                    <span className="block font-bold md:text-center p-2">
                        League
                    </span>
                </div>
                <div className="md:w-2/3">
                    {getErrorOrData(leagues, null, setSelectedLeague)}
                </div>
            </div>
            <div className="md:flex mt-1 max-w-2xl">
                <div className="md:w-1/3">
                    <span className="block font-bold md:text-center p-2">
                        Home Team
                    </span>
                </div>
                <div className="md:w-2/3">
                    {getErrorOrData(teams, "team_name")}
                </div>
            </div>
            <div className="md:flex mt-1 max-w-2xl">
                <div className="md:w-1/3">
                    <span className="block font-bold md:text-center p-2">
                        Away Team
                    </span>
                </div>
                <div className="md:w-2/3">
                    {getErrorOrData(teams, "team_name")}
                </div>
            </div>
            <div className="md:flex mt-1 max-w-2xl">
                <div className="md:w-1/3">
                    <span className="block font-bold md:text-center p-2">
                        Venue
                    </span>
                </div>
                <div className="md:w-2/3">
                    {getErrorOrData(venues)}
                </div>
            </div>
            {showPlayers ? ShowPlayersForm() : ''}
            {showPlayers ? ShowScoreInput() : ''}

        </div>
    )
}

function getErrorOrData(axiosResponse, indexForFlatArray = null, setSelectedOption) {
    let dropDownOptions = []
    if (axiosResponse.error) {
        return <p>
            There was an error, please refresh or try again later
        </p>
    }
    if (axiosResponse.data) {
        if (indexForFlatArray !== null) {
            axiosResponse.data.forEach(element => {
                if (!dropDownOptions.includes(element[indexForFlatArray])) {
                    dropDownOptions.push(element[indexForFlatArray])
                }
            });
        } else {
            dropDownOptions = axiosResponse.data
        }
    }

    return (<Dropdown
        people={dropDownOptions}
        selectedOptionSetter={setSelectedOption}
    />)
}

export default Scorecard
