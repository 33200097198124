import React from "react";

function Loader() {
    return (
        <div className="flex justify-center">
            <div className="lds-dual-ring"></div>
            
        </div>
    )
}

export default Loader