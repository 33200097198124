import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export function GetLoggedInUser() {

    const [user, setUser] = useState(null);

    useEffect(() => {
        // NOTE: check for user or risk an infinite loop
        if (!user) {
            // On component mount
            // If a session cookie exists
            // Then use it to reset auth state
            Auth.currentSession()
                .then((session) => {
                    const {
                        idToken,
                        accessToken,
                    } = session;

                    // Define your user schema per your needs
                    const user = {
                        email: idToken.payload.email,
                        username: idToken.payload.preferred_username,
                        userId: idToken.payload.sub,
                        accessToken: accessToken.jwtToken,
                    };
                    setUser(user);
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }, [user]);

    return user
}
