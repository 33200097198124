import { useEffect, useState } from 'react';
import Home from './Views/Home';
import Login from './Views/Login';
import Scorecard from './Views/Scorecard';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom'
import Layout from './Components/Layout/Layout';

function RequireAuth({ children }) {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setIsAuth(true))
      .catch(() => {
        navigate("/login")
      })
  }, [navigate])

  return isAuth && children;
}

function App() {
  // Configure Amplify in index file or root file
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_CLIENT_ID,
      mandatorySignIn: true,
      oauth: {
        domain: `${awsExports.USER_POOL_DOMAIN}.auth.eu-west-2.amazoncognito.com`,
        scope: awsExports.USER_POOL_SCOPES,
        redirectSignIn: awsExports.CALLBACK_URL,
        redirectSignOut: awsExports.CALLBACK_URL,
        responseType: 'code',
      }
    }
  })

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<Home name="Home" />} />
          <Route path='/login' element={<Login />} />
          <Route path='/scorecard' element={
            <RequireAuth>
              <Scorecard />
            </RequireAuth>
          } />
        </Routes>
      </Layout>
      {/* <Footer /> */}
    </Router>
  )
}

export default App;
