import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { BsArrowLeftCircle } from 'react-icons/bs'
import { AiFillHome } from 'react-icons/ai'
import { SiOpenaccess } from 'react-icons/si'
import { GrScorecard } from 'react-icons/gr'
// import Logo from '../assets/images/logo.svg'
import HamburgerButton from './HamburgerMenuButton/HamburgerButton'

import { Auth } from 'aws-amplify'
import { GetLoggedInUser } from '../../Hooks/GetLoggedInUser'

const Sidebar = () => {
    const [open, setOpen] = useState(true)
    const [mobileMenu, setMobileMenu] = useState(false)
    const location = useLocation()
    const authenticatedUser = GetLoggedInUser()

    const Menus = [
        { title: 'Home', path: '/', src: <AiFillHome /> },
        { title: 'Scorecard', path: '/scorecard', src: <GrScorecard /> },
    ]

    const logoutAction = () => {
        Auth.signOut()
            .then(() => {
                console.log("Logged out")
            })
            .catch(() => {
                console.log("Error catch while trying to log out")
            })
    }

    const loginAction = () => { Auth.federatedSignIn() }

    if (authenticatedUser) {
        Menus.push(
            { title: 'Signout', path: '/logout', onClick: logoutAction, src: <SiOpenaccess />, gap: 'true' }
        )
    } else {
        Menus.push(
            { title: 'Signin', path: '/login/', onClick: loginAction, src: <SiOpenaccess />, gap: 'true' }
        )
    }

    return (
        <>
            <div
                className={`${open ? 'w-60' : 'w-fit'
                    } hidden sm:block relative h-screen duration-300 bg-gray-100 border-r border-gray-200  p-5`}
            >
                <BsArrowLeftCircle
                    className={`${!open && 'rotate-180'
                        } absolute text-3xl bg-white fill-slate-800  rounded-full cursor-pointer top-9 -right-4`}
                    onClick={() => setOpen(!open)}
                />
                <Link to='/'>
                    <div className={`flex ${open && 'gap-x-4'} items-center`}>
                        {/* <img src={Logo} alt='' className='pl-2' /> */}
                        {open && (
                            <span className='text-xl font-medium whitespace-nowrap'>
                                League Portal
                            </span>
                        )}
                    </div>
                </Link>

                <ul className='pt-6'>
                    {Menus.map((menu, index) => (
                        <Link to={menu.path} key={index} onClick={menu.onClick ? menu.onClick : undefined}>
                            <li
                                className={`flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer hover:bg-gray-200 
                        ${menu.gap ? 'mt-9' : 'mt-2'} ${location.pathname === menu.path &&
                                    'bg-gray-200 '
                                    }`}
                            >
                                <span className='text-2xl'>{menu.src}</span>
                                <span
                                    className={`${!open && 'hidden'
                                        } origin-left duration-300 hover:block`}

                                >
                                    {menu.title}
                                </span>
                            </li>
                        </Link>
                    ))}
                    {/* <li className='flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer absolute inset-x-0 bottom-0' >
                        logging in as
                    </li> */}
                </ul>
            </div>
            {/* Mobile Menu */}
            <div className="pt-3">
                <HamburgerButton
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                />
            </div>
            <div className="sm:hidden">
                <div
                    className={`${mobileMenu ? 'flex' : 'hidden'
                        } absolute z-50 flex-col items-center self-end py-8 mt-16 space-y-6 font-bold sm:w-auto left-6 right-6 bg-gray-50 drop-shadow md rounded-xl`}
                >
                    {Menus.map((menu, index) => (
                        <Link
                            to={menu.path}
                            key={index}
                            onClick={() => setMobileMenu(false)}
                        >
                            <span
                                className={` ${location.pathname === menu.path &&
                                    'bg-gray-200 '
                                    } p-2 rounded-xl hover:bg-gray-200 `}
                            >
                                {menu.title}
                            </span>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Sidebar