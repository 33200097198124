export function ShowPlayersForm() {

    let playerFormItems = [
        { label: 'Player 1' },
        { label: 'Player 2' },
        { label: 'Player 3' },
        { label: 'Player 4' },
        { label: 'Player 5' },
        { label: 'Player 6' },
    ]

    return (
        <div className="mt-6 grid grid-cols-6 gap-4 max-w-2xl">
            <div className="font-bold col-span-6">Home team players</div>

            {playerFormItems.map((item, index) => (
                <>
                    <div className="col-span-3">
                        <input
                            type="text"
                            name={"home-team-" + item.label.replace(' ', '-').toLowerCase()}
                            // autoComplete="given-name"
                            className="w-full py-2 pl-3 pr-10 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder={item.label}
                        />
                    </div>
                </>
            ))}
            <div className="font-bold col-span-6">Away team players</div>
            {playerFormItems.map((item, index) => (
                <>
                    <div className="col-span-3">
                        <input
                            type="text"
                            name={"home-team-" + item.label.replace(' ', '-').toLowerCase()}
                            // autoComplete="given-name"
                            className="w-full py-2 pl-3 pr-10 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder={item.label}
                        />
                    </div>
                </>
            ))}
        </div>
    )
}