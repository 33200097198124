import React from 'react'
import { Auth } from 'aws-amplify';
import { GetLoggedInUser } from '../Hooks/GetLoggedInUser'
import { Navigate } from 'react-router-dom';


function Login() {
    let authenticatedUser = GetLoggedInUser();
    return (
        <div>
            {authenticatedUser ? (
                <Navigate to="/" />
            ) : (
                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            className="mx-auto h-10 w-auto"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            alt="Sheffield Badminton League"
                        />
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Sign in to your account
                        </h2>
                    </div >

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <div>
                            <button onClick={() => Auth.federatedSignIn()}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Sign in
                            </button>
                        </div>
                    </div>
                </div >
            )}
        </div>
    )

}

export default Login