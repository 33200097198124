import axios from "axios";
import { useEffect, useState } from "react";
import { GetLoggedInUser } from "./GetLoggedInUser";


export function useAxiosGet(url, rParams = null) {
    const [request, setRequest] = useState({
        loading: false,
        data: null,
        error: false
    })

    const authenticatedUser = GetLoggedInUser()

    useEffect(() => {
        if (authenticatedUser !== null) {
            if (rParams !== null) {
                setRequest({
                    loading: true,
                    data: null,
                    error: false
                })
                axios.get(
                    url,
                    {
                        headers: {
                            'Authorization': authenticatedUser.accessToken
                        },
                        params: rParams
                    }
                ).then(response => {
                    setRequest({
                        loading: false,
                        data: response.data,
                        error: false
                    })
                }).catch(() => {
                    setRequest({
                        loading: false,
                        data: null,
                        error: true
                    })
                })
            }
        }
    }, [url, authenticatedUser, rParams])


    return request
}