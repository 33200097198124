const aws_exports = {
    "API_GATEWAY_URL": "https://j1t5425z09.execute-api.eu-west-2.amazonaws.com",
    "CALLBACK_URL": process.env.REACT_APP_CALLBACK_URL || "http://localhost:3000",
    "REGION": "eu-west-2",
    "USER_POOL_CLIENT_ID": "153p3d9ira88vba072fvdn24c4",
    "USER_POOL_DOMAIN": "sheffield-badminton-league-portal",
    "USER_POOL_ID": "eu-west-2_AmqwPcszq",
    "USER_POOL_SCOPES": [
        "email",
        "openid"
    ],
}

export default aws_exports
